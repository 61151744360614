<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <!--<div class="ui-layout-content&#45;&#45;full ui-form-actions">
            <el-button type="primary" size="mini" @click="deploySelect()">部署</el-button>
        </div>-->
        <div class="ui-layout-content--full ui-layout-flex--1" style="margin: 20px">
            <ex-search-table-pagination
                ref="searchTable"
                :fetch="$client.getFlowList"
                :columns="columns"
                :formOptions="formOptions"
                :searchWhenSortChange="true"
                @selection-change="selectHandle"
            >
                <template slot="append">
                    <el-table-column
                        align="center"
                        label="操作"
                        type="action"
                        width="70"
                        fixed="right"
                    >
                        <template slot-scope="scope">
                            <el-tooltip content="部署" placement="top" :open-delay="200">
                                <div
                                    class="search-table-icon search-table-icon_detail"
                                    @click="flowDeploy(scope.row)"
                                    type="text"
                                ></div>
                            </el-tooltip>
                            <el-tooltip v-if="scope.row.deployVersion" content="编辑" placement="top" :open-delay="200">
                                <div
                                    class="search-table-icon search-table-icon_edit"
                                    @click="edit(scope.row)"
                                    type="text"
                                ></div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </template>
            </ex-search-table-pagination>
        </div>
        <div class="ui-layout-content--full">
            <!--新增 / 修改-->
            <el-dialog
                v-bind="addOrUpdateDialog"
                width="760px"
                :title="addOrUpdateDialog.title"
                v-if="addOrUpdateDialog.visible"
                :visible.sync="addOrUpdateDialog.visible"
                :modal-append-to-body="true"
                :close-on-click-modal="false"
                :append-to-body="true"
                :show-close="true"
            >
                <add-or-update ref="AddOrUpdate" :id="addOrUpdateDialog.currentId" :type="addOrUpdateDialog.type"></add-or-update>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addOrUpdateDialog.visible = false" size="mini">取消</el-button>
                    <el-button type="primary" @click="save" size="mini">确定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import AddOrUpdate from './flow-add-or-update';

export default {
    data() {
        return {
            selectedRecords: null,
            addOrUpdateDialog: {
                visible: false,
                title: '',
                currentId: '',
                type: '',
            },
        };
    },
    computed: {
        columns() {
            return [
                {
                    type: 'selection',
                },
                {
                    prop: 'name',
                    label: '流程名称',
                },
                {
                    prop: 'modelKey',
                    label: '流程定义key',
                },
                {
                    prop: 'lastUpdated',
                    label: '最后修改时间',
                },
                {
                    prop: 'version',
                    label: '最新版本',
                },
                {
                    prop: 'deployVersion',
                    label: '上线版本',
                },
                {
                    prop: 'status',
                    label: '状态',
                    formatter: (row, column, cellValue) => {
                        return cellValue === 1 ? '正常' : cellValue === 0 ? '禁用' : '未上线';
                    }
                },
            ];
        },
        formOptions() {
            return {
                forms: [
                    {
                        prop: 'key',
                        itemType: 'input',
                        clearable: true,
                        placeholder: '请输入参数名',
                    },
                ],
            };
        },
    },
    components: {
        AddOrUpdate
    },
    methods: {
        deploySelect() {
            if (!this.preValidate()) {
                return;
            }
            this.$confirm('部署后新发起业务将按该流程启动，确定操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                for (let row of this.selectedRecords) {
                    this.$client.deployRawFlow(row.id).then(() => {
                        this.$message({
                            title: '成功',
                            message: '流程部署成功',
                            type: 'success'
                        });
                        this.$refs.searchTable.searchHandler();
                    });
                }
            });
        },
        //流程部署
        flowDeploy(row) {
            const id = row.id;
            this.$confirm('部署后新发起业务将按该流程启动，确定操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$client.deployRawFlow(id).then(() => {
                    this.$message({
                        title: '成功',
                        message: '流程部署成功',
                        type: 'success'
                    });
                    this.$refs.searchTable.searchHandler();
                });
            });
        },
        //新增 / 修改 页面
        openAddOrUpdatePage(status) {
            //默认新增 status=1时修改
            let id = 0;
            let title = '新增';
            let type = 'add';
            if (status) {
                //修改
                if (!this.preValidate(1)) {
                    return;
                }
                id = this.selectedRecords[0].id;
                title = '修改';
                type = 'edit';
            }
            this.addOrUpdateDialog = {
                title,
                currentId: id,
                visible: true,
                type,
            };
        },
        edit(row) {
            //默认新增 status=1时修改
            let id = row.flowId;
            let title = '修改';
            let type = 'edit';
            this.addOrUpdateDialog = {
                title,
                currentId: id,
                visible: true,
                type,
            };
        },
        // 新增 / 修改
        save() {
            this.$refs.AddOrUpdate.dataFormSubmit((rest) => {
                this.addOrUpdateDialog.visible = false;
                this.$refs.searchTable.searchHandler();
            });
        },
        // 删除
        deleteHandle() {
            if (!this.preValidate()) {
                return;
            }
            let ids = [];
            this.selectedRecords.forEach(function(item) {
                ids.push(item.id);
            });
            this.$confirm(`确定删除选中流程`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$client.delFlow(ids).then((data) => {
                    if (data && data.code === 0) {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                        });
                        this.$refs.searchTable.searchHandler();
                    } else {
                        this.$message.error(data.msg);
                    }
                });
            }).catch(() => {});
        },
        selectHandle(val) {
            this.selectedRecords = val;
        },
        preValidate(isSingle) {
            if (this.selectedRecords == null || this.selectedRecords.length < 1) {
                this.$message({
                    type: 'warning',
                    message: '请先选择待操作数据'
                });
                return false;
            }
            //是否只能选一条数据的校验
            if (isSingle) {
                if (this.selectedRecords.length > 1) {
                    this.$message({
                        type: 'warning',
                        message: '只允许对一条进行操作'
                    });
                    return false;
                }
            }
            return true;
        },
    }
};
</script>
