<template>
    <div>
        <el-form
            :model="dataForm"
            :rules="dataRule"
            ref="dataForm"
            size="mini"
            :disabled="type === 'detail'"
            label-width="120px"
            @keyup.enter.native="dataFormSubmit()"
        >
            <el-row :gutter="24">
                <el-col :span="18">
                    <el-form-item :label="'流程名称'" prop="processDefinitionName">
                        <el-input v-model="dataForm.processDefinitionName"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6"></el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="18">
                    <el-form-item :label="'流程定义key'" prop="processDefinitionKey">
                        <el-input v-model="dataForm.processDefinitionKey"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6"></el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="18">
                    <el-form-item :label="'业务表单key'" prop="businessFormKey">
                        <el-input v-model="dataForm.businessFormKey"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6"></el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="18">
                    <el-form-item :label="'状态'" prop="status">
                        <el-switch v-model="dataForm.status"></el-switch>
                    </el-form-item>
                </el-col>
                <el-col :span="6"></el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="18">
                    <el-form-item label="角色" size="mini" prop="roleIdList">
                        <el-checkbox-group v-model="dataForm.roleIdList">
                            <el-checkbox v-for="role in roleList" :key="role.roleId" :label="role.roleId">{{ role.roleName }}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </el-col>
                <el-col :span="6"></el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: Number,
            default: null
        },
        type: {
            type: String,
            default: 'add'
        }
    },
    data() {
        return {
            roleList: [],
            menuListTreeProps: {
                label: 'name',
                children: 'children'
            },
            dataForm: {
                id: 0,
                processDefinitionName: '',
                processDefinitionKey: '',
                businessFormKey: '',
                status: '',
                roleIdList: []
            },
            dataRule: {
                processDefinitionName: [
                    {required: true, message: '流程名称不能为空', trigger: 'blur'}
                ],
                processDefinitionKey: [
                    {required: true, message: '流程定义key不能为空', trigger: 'blur'}
                ],
                status: [
                    {required: true, message: '状态(*,0=禁用,1=正常)不能为空', trigger: 'blur'}
                ],
                createBy: [
                    {required: true, message: '创建人员不能为空', trigger: 'blur'}
                ],
                updateBy: [
                    {required: true, message: '更新人员不能为空', trigger: 'blur'}
                ],
            },
        };
    },
    methods: {
        init(id) {
            this.getRoles();
            this.dataForm.id = id || 0;
            this.visible = true;
            this.$nextTick(() => {
                this.$refs.dataForm.resetFields();
                if (this.dataForm.id) {
                    this.$client.getFlowById(this.dataForm.id).then((data) => {
                        this.dataForm = data.flow;
                        this.dataForm.status = data.flow.status ? true : false;
                    });
                }
            });
        },
        getRoles() {
            this.$client.getAllRoleList().then((res) => {
                const list = res.list;
                this.roleList = list;
            });
        },
        // 新增 / 修改
        dataFormSubmit(cb) {
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    const submit = this.dataForm.id ? this.$client.updateFlow : this.$client.saveFlow;
                    submit({
                        id: this.dataForm.id || undefined,
                        processDefinitionName: this.dataForm.processDefinitionName,
                        processDefinitionKey: this.dataForm.processDefinitionKey,
                        businessFormKey: this.dataForm.businessFormKey,
                        status: this.dataForm.status ? 1 : 0,
                        roleIdList: this.dataForm.roleIdList,
                    }).then(() => {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500
                        });
                        this.visible = false;
                        this.$emit('refreshDataList');
                    });
                }
                cb();
            });
        },
    },
    created() {
        this.dataForm.id = this.id;
        this.init(this.dataForm.id);
    }
};
</script>
